<template>
  <div style="height: 100%;">
    <v-navigation-drawer
      color="btPrimary"
      app
      dark
      permanent
      stateless
      clipped
    >
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              :to="item.to"
              class="py-1"
              link
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.text"
                  class="font-weight-bold"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
  
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      menuItems: [
        {
          text: "ユーザーマスタ",
          to: { name: "MasterMente_user" },
        },
        {
          text: "部署マスタ",
          to: { name: "MasterMente_department" },
        },
        {
          text: "選択項目マスタ",
          to: { name: "MasterMente_selectoritem" },
        },
      ],

    }
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  beforeRouteEnter(to, from ,next) {
    // スーパーユーザーでない場合はトップページへリダイレクトする
    next(vm => {
      if(vm.$store.getters.userInfo.superUser){
        next();
      } else {
        next('/top');
      }
    })
  }
};
</script>

<style lang="scss" scoped>
</style>
